import { hyphenateSync } from 'hyphen/no';
import store from '../../store';

type HyphenateTitleElementProps = {
    title: string;
    minWordLength?: number;
};

export const HyphenateTitleElement = (props: HyphenateTitleElementProps) => {
    if (props.title == null || props.title == '' || props.title.length < 1) {
        return <></>;
    }
    if (props.title.length == 1) {
        return <>{props.title}</>;
    }
    let min_word_length = store.getState()?.hyphensMinWordLength != null ? store.getState()?.hyphensMinWordLength : 12;
    if (props.minWordLength != null) {
        min_word_length = props.minWordLength;
    }
    return <>{hyphenateSync(props.title, { minWordLength: min_word_length })}</>;
};

export default HyphenateTitleElement;
